.container {
  max-width: 900px;
}

.iconGrid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 15px;
  margin-top: 20px;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 992px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 576px) {
    grid-template-columns: 1fr;
  }
}

.logoContainer {
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
  border-radius: 50%;
  border: 2px solid #ddd;
  text-align: center;
  font-size: 12px;
  color: #999;
}

.logoImg {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: contain;
}

.defaultLogo {
  width: 100%;
  height: 100%;
}

.iconItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px 0;

  svg {
    font-size: 40px;
    color: #002b36;
    height: 100px;
    width: 100px;
    margin-bottom: 10px;
  }
}

.iconName {
  font-size: 12px;
  color: #666;
  margin-bottom: 8px;
  text-align: center;
}

.largeButton {
  width: 100%;
  padding: 15px;
  font-size: 18px;
  border-radius: 30px;
  background-color: #002b36;
  color: #fff;
  margin-top: 20px;

  &:hover {
    background-color: #001f29;
  }
}
