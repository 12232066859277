.eventPageContainer {
  width: 65%;
  height: 100%;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.eventCard {
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: auto;
}

.formGroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.datePickerGroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  label {
    margin-bottom: 8px;
    font-size: 16px;
  }

  .form-control {
    width: 100%;
    padding: 10px;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }
}

.switchGroup {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
  margin-bottom: 30px;

  .form-group {
    flex: 1;
  }

  .form-check-label {
    margin-right: 10px;
  }
}

.buttonGroup {
  display: flex;
  justify-content: space-between;
}

.largeButton {
  width: 100%;
  padding: 15px;
  font-size: 20px;
  border-radius: 30px;
  background-color: #002b36;
  color: #fff;
  margin-top: 30px;

  &:hover {
    background-color: #001f29;
  }
}

.imagePreview {
  margin-top: 20px;
  text-align: center;

  img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    border: 2px solid #ddd;
  }
}

.text-danger {
  color: #dc3545;
  font-size: 14px;
}

.mt-3 {
  margin-top: 1rem;
}

.spinner-border {
  display: block;
  margin: 30px auto;
}

.alert {
  text-align: center;
  font-size: 18px;
  margin-top: 20px;
}

.eventList {
  margin-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
}

.eventRow {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.eventItem {
  display: flex;
  flex-direction: column;
  width: calc(50% - 20px);
  min-width: 300px;
  border: 1px solid #ccc;
  border-radius: 15px;
  background-color: #f9f9f9;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }

  @media (max-width: 768px) {
    width: 100%;
  }
}

.eventContent {
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.eventTitle {
  font-size: 1.2em;
  font-weight: 600;
  color: #333;
  margin: 0;
}

.eventDescription {
  color: #666;
  margin: 10px 0;
  line-height: 1.4;
}

.eventDateTime {
  font-size: 0.9em;
  color: #555;
  margin-top: 10px;
  font-weight: 500;

  text-align: left;

  span {
    display: block;
    width: 100%;
    margin-bottom: 5px;
  }
}

.eventPlakat {
  display: flex;
  justify-content: center;
  margin-top: 15px;

  .plakatImage {
    max-width: 100%;
    max-height: 200px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    cursor: pointer;

    &:hover {
      transform: scale(1.05);
    }
  }
}

.eventActions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding: 15px;
  border-top: 1px solid #ddd;

  .editIcon {
    color: #007bff;
    &:hover {
      color: #0056b3;
    }
  }

  .deleteIcon {
    color: #dc3545;
    &:hover {
      color: #a71d2a;
    }
  }

  .editIcon,
  .deleteIcon {
    font-size: 1.2em;
    cursor: pointer;
    transition: color 0.2s ease;
  }
}

.inactiveEvent {
  opacity: 0.3;
  filter: grayscale(70%);
}

.titleHeader {
  display: flex;
  justify-content: space-between;
}
