@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.skeletonRow {
  padding: 20px 15px;
  max-width: 700px;
  margin: 0 auto;
  background-color: #f0f0f0;
  margin-bottom: 20px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.skeletonHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.skeletonDate,
.skeletonButton,
.skeletonTime {
  background: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
  background-size: 200% 100%;
  animation: shimmer 6s infinite linear;
}

.skeletonDate {
  width: 30%;
  height: 30px;
  border-radius: 4px;
}

.skeletonActions {
  display: flex;
  gap: 10px;
  width: 40%;
}

.skeletonButton {
  width: 30%;
  height: 30px;
  border-radius: 16px;
}

.skeletonPrayerTimes {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.skeletonTime {
  width: 18%;
  height: 40px;
  border-radius: 4px;
}
