@import "bootstrap/dist/css/bootstrap.css";
@import "./global.scss";

html,
body {
  background-color: #f4f7fa !important;
}

.App {
  background-color: color(secondary, 50);
  display: flex;
  height: 100vh;
}

.side-panel {
  width: 200px;
  background-color: color(primary, 300);
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  padding: 10px;
  overflow-y: auto;

  & > * {
    width: 100%;
  }
}

.content {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: #f4f7fa;
}

.custom-form .form-check-input {
  width: 50px !important;
  height: 25px;
}

.custom-form .form-check-input:checked {
  background-color: #0d6efd;
}
