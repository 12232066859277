@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.skeletonContainer {
  width: 50%;
  margin-top: 50px;
}

.skeletonCard {
  margin-bottom: 20px;
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.skeletonHeader,
.skeletonInput,
.skeletonButton,
.skeletonLogo {
  background: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
  background-size: 200% 100%;
  animation: shimmer 3s infinite linear;
}

.skeletonHeader {
  width: 30%;
  height: 30px;
  border-radius: 4px;
  margin-bottom: 20px;
}

.skeletonInput {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  margin-bottom: 20px;
}

.skeletonButton {
  width: 30%;
  height: 40px;
  border-radius: 4px;
  margin-bottom: 20px;
}

.skeletonLogo {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin: 20px auto;
}
