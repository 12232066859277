.pageHeader {
  padding: 20px;
  border-bottom: 2px solid #e0e0e0;

  .pageHeaderTitle {
    margin: 0;
    font-size: 2rem;
    color: #333;
  }
}
