.storybookTile {
  font-family: "Nunito Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 500;
  border: 0;
  font-size: 18px;
  height: 50px;
  width: 100%;
  cursor: pointer;
  background-color: transparent;
  text-align: start;

  &Primary {
    color: rgba(250, 250, 250, 0.657);

    &:hover {
      color: white;
    }
  }

  &Secondary {
    color: #333;
    background-color: transparent;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 1px inset;

    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }

  &Medium {
    padding: 40px 40px;
  }

  &Large {
    padding: 12px 24px;
  }
}
