.posterPageContainer {
  width: 65%;
  padding: 40px 0;
}

.posterCard {
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  margin-bottom: 30px;
}

.formGroup {
  margin-bottom: 30px;
}

.largeButton {
  width: 100%;
  padding: 15px;
  font-size: 18px;
  border-radius: 30px;
  background-color: #002b36;
  color: #fff;
  margin-top: 20px;

  &:hover {
    background-color: #001f29;
  }
}

.form-control {
  font-size: 18px;
  padding: 12px 15px;
  border-radius: 8px;
  border: 1px solid #ced4da;
}

.form-label {
  font-size: 18px;
}

.imagePreview {
  margin-top: 20px;

  img {
    max-height: 300px;
    width: auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
}

.posterTitle {
  font-size: 20px;
  font-weight: bold;
}

.poster-list {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}

.alert {
  text-align: center;
  font-size: 18px;
  margin-top: 20px;
  padding: 15px;
}

.modal-content {
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.modal-body img {
  max-height: 500px;
  width: 100%;
  object-fit: cover;
}

.spinner-border {
  display: block;
  margin: 30px auto;
}

.editIcon {
  color: #007bff;
  cursor: pointer;
  padding-right: 7px;
  &:hover {
    color: #0056b3;
  }
}

.deleteIcon {
  color: #dc3545;
  cursor: pointer;
  &:hover {
    color: #a71d2a;
  }
}

.editIcon,
.deleteIcon {
  height: 19px;
  width: 19px;
}
