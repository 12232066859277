.monthContainer {
  display: flex;
  gap: 20px;
  justify-content: center;
}
.monthNavigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;

  button {
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      background-color: #0056b3;
    }
  }
}

h1 {
  font-size: 32px;
}
