.loginContainer {
  height: 100vh;
  display: flex;
  flex-direction: row;

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.leftSection {
  flex: 1;
  background-color: #e9ecef;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    display: none;
  }
}

.rightSection {
  flex: 1;
  padding: 2rem;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  h1 {
    font-size: 2rem;
    margin-bottom: 1.5rem;
    text-align: center;

    @media (max-width: 576px) {
      font-size: 1.5rem;
      margin-bottom: 1rem;
    }
  }

  .formLabel {
    margin-bottom: 0.5rem;
  }

  .formControl {
    margin-bottom: 1rem;
    width: 100%;
  }

  .btnPrimary {
    width: 100%;
    padding: 0.75rem;
    font-size: 1rem;

    @media (max-width: 576px) {
      padding: 0.5rem;
      font-size: 0.875rem;
    }
  }

  .errorMessage {
    color: #dc3545;
    font-size: 0.875rem;
    margin-top: 0.25rem;
    text-align: left;

    @media (max-width: 576px) {
      font-size: 0.8rem;
    }
  }
}

.icon {
  width: 60px;
  height: 60px;
  margin-bottom: 1.5rem;

  @media (max-width: 576px) {
    width: 50px;
    height: 50px;
  }
}
