$colors: (
  primary: (
    default: #093b51,
    100: #cad6e7,
    200: #a4b8a4,
    300: #3a4f4c,
    400: #a58ddd,
    500: #8767d1,
    600: #6941c6,
    700: #54349e,
    800: #3f2777,
    900: #2a1a4f,
  ),
  secondary: (
    default: #033b51,
    50: #cad6e7,
    100: #f2f4f7,
    200: #e4e7ec,
    300: #d0d5dd,
    400: #98a2b3,
    500: #667085,
    600: #475467,
    700: #344054,
    800: #1d2939,
    900: #101828,
  ),
  success: (
    default: #43cb6a,
    100: #f1faf3,
    200: #e2f6e8,
    300: #c5ecd1,
    400: #a8e3b9,
    500: #8bd9a2,
    600: #6ed08b,
    700: #58a66f,
    800: #427d53,
    900: #2c5338,
  ),
  info: (
    default: #2e90fa,
    100: #eaf4ff,
    200: #d5e9fe,
    300: #abd3fd,
    400: #82bcfc,
    500: #58a6fb,
    600: #2e90fa,
    700: #2573c8,
    800: #1c5696,
    900: #123a64,
  ),
  error: (
    default: #db5c54,
    100: #fbefee,
    200: #f8dedd,
    300: #f1bebb,
    400: #e99d98,
    500: #e27d76,
    600: #db5c54,
    700: #af4a43,
    800: #833732,
    900: #582522,
  ),
  warning: (
    default: #fec84b,
    100: #fffaed,
    200: #fff4db,
    300: #ffe9b7,
    400: #fede93,
    500: #fed36f,
    600: #fec84b,
    700: #cba03c,
    800: #98782d,
    900: #66501e,
  ),
  background: (
    default: #ffffff,
  ),
);

@function color($category, $level: default) {
  @return map-get(map-get($colors, $category), $level);
}

/* Apply some of the custom properties */
html {
  font-size: 16px;
  background-color: color(background, default);
}
