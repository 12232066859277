@import "assets/global.scss";

.storybookButton {
  font-family: "Nunito Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  border: 0;
  border-radius: 3em;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  line-height: 1;
  transition: background-color 0.3s ease;
  text-decoration: none;

  &Primary {
    color: white;
    background-color: color(success, default);

    &:hover {
      background-color: lighten(color(success, default), 10%);
    }
  }

  &Secondary {
    color: white;
    background-color: color(secondary, default);

    &:hover {
      background-color: lighten(color(secondary, default), 10%);
    }
  }

  &Info {
    color: white;
    background-color: color(info, default);

    &:hover {
      background-color: lighten(color(info, default), 10%);
    }
  }

  &Error {
    color: white;
    background-color: color(error, default);

    &:hover {
      background-color: lighten(color(error, default), 10%);
    }
  }

  &FullWidthTransparentPrimary {
    color: #9ca3af;
    background-color: transparent;
    border-radius: 10px;
    display: flex;
    gap: 20px;
    width: 100%;

    &:hover {
      background-color: rgba(230, 233, 230, 0.444);
      color: white;
    }
  }

  &Small {
    font-size: 12px;
    padding: 10px 16px;
  }

  &Medium {
    font-size: 14px;
    padding: 11px 20px;
  }

  &Large {
    font-size: 16px;
    padding: 12px 24px;
  }

  &Disabled {
    background-color: #d3d3d3;
    color: #a9a9a9;
    cursor: default;
    opacity: 0.6;

    &:hover,
    &:active,
    &:focus {
      background-color: #d3d3d3;
      color: #a9a9a9;
    }
  }

  .iconWrapper {
    display: flex;
    align-items: center;

    svg {
      width: 15px;
      height: 15px;
    }
  }
}
