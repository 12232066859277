.noContentCard {
  border: none;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  padding: 10px;
  background-color: #f8f9fa;
  color: #6c757d;
  border-radius: 4px;

  h4 {
    margin-top: 5px;
    font-weight: 400;
    font-size: 0.85rem;
    color: #6c757d;
  }

  .faInbox {
    color: #6c757d;
    font-size: 1.2rem;
  }

  &.compact {
    padding: 5px;
    margin-top: 5px;

    h4 {
      margin-top: 2px;
      font-size: 0.75rem;
    }

    .faInbox {
      font-size: 1rem;
    }
  }
}
