.container {
  max-width: 900px;
  margin: 20px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

  h2 {
    font-size: 1.8em;
    text-align: center;
    margin-bottom: 20px;
    color: #002b36;
  }

  .formGroup {
    margin-bottom: 1.5rem;

    label {
      font-weight: bold;
      color: #444;
      font-size: 1.05em;
      margin-bottom: 5px;
      display: block;
    }

    .formControl {
      width: 100%;
      padding: 10px 12px;
      border: 1px solid #ddd;
      border-radius: 6px;
      transition:
        border-color 0.2s ease,
        box-shadow 0.2s ease;
      background-color: #fff;
      font-size: 1em;

      &:focus {
        border-color: #007bff;
        box-shadow: 0 0 5px rgba(0, 123, 255, 0.2);
        outline: none;
      }
    }

    .textDanger {
      color: #d9534f;
      font-size: 0.9em;
      margin-top: 4px;
    }
  }

  .largeButton {
    width: 100%;
    padding: 15px;
    font-size: 18px;
    font-weight: bold;
    border-radius: 30px;
    background-color: #002b36;
    color: #fff;
    transition: background-color 0.2s ease;
    text-align: center;
    cursor: pointer;
    margin-top: 20px;
    border: none;

    &:hover {
      background-color: #001f29;
    }

    &:disabled {
      background-color: #e9ecef;
      color: #6c757d;
      cursor: not-allowed;
    }
  }

  @media (max-width: 768px) {
    padding: 15px;

    h2 {
      font-size: 1.5em;
      margin-bottom: 15px;
    }

    .formGroup {
      margin-bottom: 1rem;

      label {
        font-size: 0.95em;
      }

      .formControl {
        padding: 8px 10px;
        font-size: 0.95em;

        &:disabled {
          background-color: #e9ecef;
          color: #6c757d;
          cursor: not-allowed;
        }
      }
    }

    .largeButton {
      padding: 12px;
      font-size: 16px;
    }

    .alert {
      font-size: 0.95em;
      padding: 10px;
    }
  }

  @media (max-width: 576px) {
    h2 {
      font-size: 1.2em;
    }

    .largeButton {
      padding: 10px;
      font-size: 15px;
      border-radius: 20px;
    }
  }
}

.card {
  margin-bottom: 1.5rem;
  border: none;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);

  .cardHeader {
    background-color: #002b36;
    color: white;
    font-weight: bold;
    font-size: 1.15em;
    padding: 12px 20px;
  }

  .cardBody {
    padding: 20px;

    @media (max-width: 576px) {
      padding: 15px;
    }
  }
}

.alert {
  font-size: 1em;
  font-weight: 500;
  border-radius: 6px;
  text-align: center;
  margin-bottom: 20px;

  @media (max-width: 576px) {
    font-size: 0.9em;
  }
}

.logoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  margin-bottom: 20px;

  @media (max-width: 576px) {
    height: 80px;
    margin-bottom: 15px;
  }
}

.logoImage {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}

.description {
  font-size: 1rem;
  color: #666;
  text-align: center;
  margin: 10px 0 20px;
  line-height: 1.6;

  @media (max-width: 576px) {
    font-size: 0.9em;
    line-height: 1.4;
  }
}

.checkBoxGroup {
  display: flex;
  gap: 10px;
  align-items: center;

  .checkbox {
    min-width: 20px;
    min-height: 20px;
  }

  .checkBoxLabelGroup {
    font-size: 0.9em;
  }
}

.disabledDropdown {
  background-color: #e9ecef !important;
  color: #6c757d;
  pointer-events: none;
}
