.expressionsContainer {
  padding: 20px;
}

.expressionsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.expressionCard {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
}

.cardBody {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.buttonGroup {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.arabicText {
  font-size: 1.5em;
  color: #333;
}

.translitText {
  font-style: italic;
  color: #666;
}

.translationText {
  margin-top: 10px;
  font-weight: bold;
}

.situationText {
  font-size: 0.9em;
  color: #888;
}
