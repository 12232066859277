.largeButton {
  width: 100%;
  padding: 15px;
  font-size: 18px;
  border-radius: 30px;
  background-color: #002b36;
  color: #fff;
  margin-top: 20px;
  margin-bottom: 20px;

  &:hover {
    background-color: #001f29;
  }
}
