.informationPageContainer {
  width: 65%;
  height: 100%;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.informationCard {
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: auto;
}

.formGroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.datePickerGroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  label {
    margin-bottom: 8px;
    font-size: 16px;
  }

  .form-control {
    width: 100%;
    padding: 10px;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }
}

.colorSelector {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 30px;

  .colorOption {
    width: 150px;
    height: 100px;
    border-radius: 10px;
    cursor: pointer;
    transition: border 0.2s ease-in-out;

    &:hover {
      border: 2px solid black;
    }
  }
}

.text-danger {
  color: #dc3545;
  font-size: 14px;
}

.mt-3 {
  margin-top: 1rem;
}

.alert {
  text-align: center;
  font-size: 18px;
  margin-top: 20px;
}

.spinner-border {
  display: block;
  margin: 30px auto;
}

.largeButton {
  width: 100%;
  padding: 15px;
  font-size: 20px;
  border-radius: 30px;
  background-color: #002b36;
  color: #fff;
  margin-top: 30px;

  &:hover {
    background-color: #001f29;
  }
}

.informationList {
  margin-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.informationItem {
  width: calc(50% - 20px);
  min-width: 300px;
  border-radius: 10px;
  border: 2px solid transparent;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
  }

  .card-body {
    padding: 20px;
  }
}

.colorStrip {
  width: 100%;
  height: 8px;
  position: absolute;
  top: 0;
  left: 0;
}

.informationContent {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title {
    font-size: 18px;
    font-weight: bold;
    color: #333;
  }

  .description {
    font-size: 15px;
    color: #555;
    margin-top: 8px;
  }

  .imageContainer {
    flex-shrink: 0;
    width: 80px;
    height: 80px;
    overflow: hidden;
    border-radius: 5px;
    margin-left: 15px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.informationFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  font-size: 13px;

  .date,
  .visibility {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .informationActions {
    display: flex;
    gap: 10px;
    color: #888;

    .editIcon {
      color: #007bff;
      cursor: pointer;
      &:hover {
        color: #0056b3;
      }
    }

    .deleteIcon {
      color: #dc3545;
      cursor: pointer;
      &:hover {
        color: #a71d2a;
      }
    }

    .editIcon,
    .deleteIcon {
      height: 19px;
      width: 19px;
    }
  }
}

@media (max-width: 768px) {
  .informationItem {
    width: 100%;
  }
}
