@import "assets/global.scss";

.prayerTimesPage {
  padding: 0 15px;

  @media (max-width: 768px) {
    padding: 0 10px;
  }
}

.monthContainer {
  margin-bottom: 20px;

  label {
    font-size: 1rem;
    margin-bottom: 5px;
    display: block;
  }

  .form-select {
    width: 100%;
    margin-top: 5px;
  }

  @media (max-width: 768px) {
    label {
      font-size: 0.9rem;
    }

    .form-select {
      font-size: 0.85rem;
    }
  }
}

.prayerTimesTable {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  margin-bottom: 40px;
  table-layout: fixed;

  th,
  td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: center;
  }

  th {
    background-color: #3e497a;
    color: #fff;
    font-size: 1rem;
  }

  td {
    background-color: #f2f2f2;
  }

  td:nth-child(odd) {
    background-color: #c1bbbb4a;
  }

  @media (max-width: 768px) {
    display: block;
    overflow-x: auto;
    white-space: nowrap;

    th,
    td {
      font-size: 0.85rem;
      padding: 6px;
    }

    th {
      font-size: 0.9rem;
    }
  }
}

.alert {
  margin-top: 20px;
}

.dropdownContainer {
  margin-bottom: 20px;

  label {
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
  }

  .form-select {
    width: 100%;
    padding: 8px;
  }

  @media (max-width: 768px) {
    label {
      font-size: 0.9rem;
    }

    .form-select {
      font-size: 0.85rem;
      padding: 6px;
    }
  }
}
