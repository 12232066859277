@import "assets/global.scss";

.sideMenu {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100vh;
  background-color: #093b51;
  max-width: 270px;
  overflow: auto;

  a {
    text-decoration: none;
  }
}
