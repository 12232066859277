.notificationListContainer {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.notificationTable {
  th,
  td {
    background-color: #f8f9fa;
  }
}

.notificationRow {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.notificationItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  padding: 30px;
  width: calc(50% - 20px);
  min-width: 300px;
  border: 1px solid #ccc;
  border-radius: 15px;
  background-color: #ffffff;
  box-sizing: border-box;
  margin-bottom: 20px;

  h3 {
    margin-top: 0;
  }

  p {
    margin: 5px 0;
    font-size: 14px;
    color: #6c757d;
  }

  .notificationDate {
    font-size: 12px;
    color: #888;
    margin-top: 10px;
  }

  .notificationActions {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
}

@media (max-width: 768px) {
  .notificationItem {
    width: 100%;
  }
}
