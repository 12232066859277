// MosqueSelection.module.scss

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f4f4f9;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 40px;
  text-align: center;
  padding: 30px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 500px;
}

.heading {
  margin-bottom: 40px;
  margin-top: 40px;
  font-size: 1.8rem;
  color: #333333;
}

.select {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border-radius: 5px;
  border: 1px solid #cccccc;
  outline: none;
}
