@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.prayerTimesTable {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  margin-bottom: 40px;
}

.prayerTimesTable th,
.prayerTimesTable td {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: center;
  width: calc(100% / 6);
}

.prayerTimesTable th {
  background-color: #3e497a;
  color: white;
}

.prayerTimesTable td {
  background-color: #f2f2f2;
}

.skeletonCell {
  padding: 10px;
}

.skeleton {
  background: linear-gradient(90deg, #ccc 25%, #f2f2f2 50%, #ccc 75%);
  background-size: 200% 100%;
  height: 20px;
  width: 100%;
  border-radius: 4px;
  animation: shimmer 4s infinite linear;
}
