.campaignPageContainer {
  width: 65%;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px; /* Added gap to create space between the form and the list */
}

.campaignCard {
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 800px; /* Limiting max width for better readability */
  margin-bottom: 40px; /* Spacing between the form and campaign list */
}

.formGroup {
  margin-bottom: 30px;
}

.text-danger {
  color: #dc3545;
  font-size: 14px;
}

.mt-3 {
  margin-top: 1rem;
}

.alert {
  text-align: center;
  font-size: 18px;
  margin-top: 20px;
}

.largeButton {
  width: 100%;
  padding: 15px;
  font-size: 20px;
  border-radius: 30px;
  background-color: #002b36;
  color: #fff;
  margin-top: 30px;

  &:hover {
    background-color: #001f29;
  }
}

/* Campaign List Styles */
.campaignList {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.campaignItem {
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd;
  transition: box-shadow 0.3s ease-in-out;

  &:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }

  h4 {
    margin-top: 0;
    font-size: 22px;
    color: #333;
  }

  p {
    margin: 10px 0;
    font-size: 16px;
    color: #555;
  }

  strong {
    font-weight: bold;
    color: #333;
  }
}

.editIcon {
  color: #007bff;
  cursor: pointer;
  &:hover {
    color: #0056b3;
  }
}

.deleteIcon {
  color: #dc3545;
  cursor: pointer;
  &:hover {
    color: #a71d2a;
  }
}

.editIcon,
.deleteIcon {
  height: 19px;
  width: 19px;
}

/* Adjust layout for smaller screens */
@media (max-width: 768px) {
  .campaignPageContainer {
    width: 90%;
    padding: 20px;
  }

  .campaignCard {
    padding: 20px;
    margin-bottom: 30px;
  }

  .campaignItem {
    padding: 15px;
  }

  .largeButton {
    padding: 12px;
    font-size: 18px;
  }
}
