@import "../../../assets/global.scss";

.prayerRow {
  padding: 10px 15px;
  max-width: 700px;
  margin: 0 auto;
  background-color: white;
  margin-bottom: 20px;
  border-radius: 16px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
    }
  }

  .date {
    flex: 1;
    font-weight: bold;
    font-size: 20px;

    @media (max-width: 768px) {
      font-size: 18px;
      margin-bottom: 10px;
    }
  }

  .prayerTimes {
    flex: 3;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    width: 100%;

    @media (max-width: 768px) {
      flex-direction: column;
      gap: 15px;
    }
  }

  .prayerTime {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 18%;

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .prayerLabel {
    margin-bottom: 5px;
    text-align: center;

    @media (max-width: 768px) {
      font-size: 14px;
    }
  }

  input[type="text"] {
    width: 100%;
    padding: 10px;
    text-align: center;
    border-radius: 16px;
    border: 1px solid #ccc;
    box-sizing: border-box;

    @media (max-width: 768px) {
      padding: 8px;
    }
  }

  .actions {
    flex: 1;
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    width: 100%;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      margin-top: 10px;
    }

    button {
      padding: 10px 12px;
      font-size: 14px;
      width: auto;
      justify-content: center;

      @media (max-width: 768px) {
        width: 100%;
        box-sizing: border-box;
        font-size: 16px;
        margin-top: 5px;
      }
    }
  }
}
