.container {
  max-width: 900px;
  min-width: 100%;
  margin: 20px auto;
  padding: 10px;

  @media (max-width: 768px) {
    padding: 0 15px;
  }
}

.card {
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 20px;

  .card-header {
    background-color: #002b36;
    color: #fff;
    font-weight: bold;
    text-align: center;
    padding: 10px 15px;
  }

  .card-body {
    padding: 15px;

    @media (max-width: 576px) {
      padding: 10px;
    }
  }
}

.form-control {
  font-size: 1rem;
  padding: 10px;

  @media (max-width: 576px) {
    font-size: 0.9rem;
    padding: 8px;
  }
}

.largeButton {
  width: 100%;
  padding: 15px;
  font-size: 18px;
  border-radius: 8px;
  background-color: #002b36;
  color: #fff;
  margin-top: 20px;

  &:hover {
    background-color: #001f29;
  }

  @media (max-width: 576px) {
    font-size: 16px;
    padding: 12px;
  }
}

.iconGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
  margin-top: 20px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 576px) {
    grid-template-columns: 1fr;
  }
}

.modal {
  .modal-dialog {
    max-width: 95%;
    margin: 0 auto;

    @media (max-width: 576px) {
      max-width: 100%;
      margin: 0 10px;
    }
  }

  .modal-header,
  .modal-body,
  .modal-footer {
    padding: 10px 15px;

    @media (max-width: 576px) {
      padding: 8px;
    }
  }

  .form-control {
    font-size: 1rem;
    padding: 10px;

    @media (max-width: 576px) {
      font-size: 0.9rem;
      padding: 8px;
    }
  }
}

.pendingRequestButton {
  background-color: #d1ecf1 !important;
  color: #000 !important;
  border: 1px solid #bee5eb;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px 15px;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.05);
    border: none;
  }
}
