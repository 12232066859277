.blogPageContainer {
  width: 65%;
  height: 100%;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.blogCard {
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: auto;
}

.formGroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.datePickerGroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  label {
    margin-bottom: 8px;
    font-size: 16px;
  }

  .form-control {
    width: 100%;
    padding: 10px;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }
}

.switchGroup {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
  margin-bottom: 30px;

  .form-group {
    flex: 1;
  }

  .form-check-label {
    margin-right: 10px;
  }
}

.buttonGroup {
  display: flex;
  justify-content: space-between;
}

.largeButton {
  width: 100%;
  padding: 15px;
  font-size: 20px;
  border-radius: 30px;
  background-color: #002b36;
  color: #fff;
  margin-top: 30px;

  &:hover {
    background-color: #001f29;
  }
}

.imagePreview {
  margin-top: 20px;
  text-align: center;

  img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    border: 2px solid #ddd;
  }
}

.text-danger {
  color: #dc3545;
  font-size: 14px;
}

.mt-3 {
  margin-top: 1rem;
}

.spinner-border {
  display: block;
  margin: 30px auto;
}

.alert {
  text-align: center;
  font-size: 18px;
  margin-top: 20px;
}

.blogList {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
}

.blogItem {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  width: calc(50% - 20px);
  min-width: 300px;
  border: 1px solid #ccc;
  border-radius: 12px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  }
}

.blogContent {
  margin-bottom: 10px;
}

.blogPlakat {
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  img {
    transition: transform 0.3s ease;
    max-width: 100%;
    max-height: 200px;
    object-fit: cover;
    border-radius: 8px;
    cursor: pointer;
  }
}

.blogActions {
  display: flex;
  justify-content: flex-end;
  gap: 15px;

  svg {
    height: 19px;
    width: 19px;
  }
}

.editIcon {
  color: #007bff;
  cursor: pointer;
  &:hover {
    color: #0056b3;
  }
}

.deleteIcon {
  color: #dc3545;
  cursor: pointer;
  &:hover {
    color: #a71d2a;
  }
}

.blogTitleWithIcon {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footerContent {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .blogItem {
    width: 100%;
  }
}
