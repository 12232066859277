.notificationPageContainer {
  width: 65%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 50px auto;
  padding: 40px;
}

.notificationCard {
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.formGroup {
  margin-bottom: 30px;
}

.largeButton {
  width: 100%;
  padding: 15px;
  font-size: 20px;
  border-radius: 30px;
  background-color: #002b36;
  color: #fff;
  margin-top: 30px;

  &:hover {
    background-color: #001f29;
  }
}

.form-control {
  font-size: 18px;
  padding: 12px 15px;
  border-radius: 8px;
}

.form-label {
  font-size: 18px;
}

.spinner-border {
  display: block;
  margin: 30px auto;
}

.alert {
  text-align: center;
  font-size: 18px;
  margin-top: 20px;
}

.notificationCard h2 {
  margin-bottom: 30px;
}
