.allahNamesContainer {
  padding: 20px;
  text-align: center;

  h2 {
    font-family: "Arial", sans-serif;
    color: #2e4053;
    font-weight: bold;
    font-size: 1.8rem;
    margin-bottom: 20px;
  }
}

.namesGrid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.nameCard {
  width: 220px;
  border: 1px solid #e2e6ea;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
  }
}

.arabicName {
  font-size: 1.5rem;
  font-weight: bold;
  color: #1d3557;
}

.translitName {
  font-size: 1rem;
  font-style: italic;
  color: #6c757d;
}

.translationName {
  font-size: 0.9rem;
  color: #495057;
  margin-top: 10px;
}
