.menuItem {
  padding: 0 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.fullWidthButton {
  width: 100%;
  padding: 10px 10px;
  font-size: 1rem;
  text-align: start;

  &:hover {
    background-color: unset;
  }
}
