@import "assets/global.scss";

.monthContainer {
  margin-bottom: 20px;

  h3 {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }

  .row {
    display: flex;
    flex-direction: row;
    gap: 5px;
  }

  .form-select {
    width: 100%;
  }
}

// For smaller screens, make the table horizontally scrollable
.prayerTimesTable {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  margin-bottom: 40px;
  table-layout: fixed;

  // Wrap the table in a scrollable container on mobile
  @media (max-width: 768px) {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
    table-layout: auto;
  }

  th,
  td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: center;

    @media (max-width: 768px) {
      font-size: 0.85rem;
      padding: 6px;
    }
  }

  th {
    background-color: #3e497a;
    color: white;
    font-size: 1rem;

    @media (max-width: 768px) {
      font-size: 0.9rem;
    }
  }

  td {
    background-color: #f2f2f2;
  }

  td:nth-child(odd) {
    background-color: #c1bbbb4a;
  }
}

.prayerTimesPage {
  padding: 0 15px;

  @media (max-width: 768px) {
    padding: 0 10px;
  }
}
